var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-1 p-0 mx-0 waybill-index-top-nav-select"},[_c('ul',{staticClass:"dashboard-tabs nav nav-pills nav-primary d-flex flex-nowrap m-0 p-0",attrs:{"role":"tablist"}},_vm._l((_vm.data),function(row,index){return (Number(row.parent_id) === 0)?_c('li',{key:index,staticClass:"rounded d-flex align-items-center justify-content-center items-center my-li nav-item mb-lg-0",on:{"click":function($event){_vm.modelProxy = row.id}}},[_c('a',{staticClass:"d-flex align-items-center justify-content-center items-center",class:{ active: _vm.isActive(row.id) },attrs:{"data-toggle":"pill"}},[_c('span',{staticClass:"nav-icon w-auto d-flex align-items-center row justify-content-center items-center text-center"},[(row.id === 7)?_c('span',{staticClass:"col-12 text-center waybill-nav-icon",domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.waybill.transfer', {
            class: 'w-20px h-20px d-inline-block object-fill cursor-pointer',
            style: _vm.isActive(row.id) ? '' : 'opacity: 0.5;'
          }))}}):_vm._e(),(row.id === 2)?_c('span',{staticClass:"col-12 text-center waybill-nav-icon",domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.waybill.accessory', {
            class: 'w-20px h-20px d-inline-block object-fill cursor-pointer',
            style: _vm.isActive(row.id) ? '' : 'opacity: 0.5;'
          }))}}):_vm._e(),(row.id === 1)?_c('span',{staticClass:"col-12 text-center waybill-nav-icon",domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.waybill.fabric', {
            class: 'w-20px h-20px d-inline-block object-fill cursor-pointer',
            style: _vm.isActive(row.id) ? '' : 'opacity: 0.5;'
          }))}}):_vm._e(),_c('span',{staticClass:"nav-text col-12 py-2 px-2 font-weight-boldest text-center d-inline-block pl-2 second-waybill-naw-text",attrs:{"id":_vm.isActive(row.id) ? 'active-navy' : ''}},[_vm._v(_vm._s(row.translations[0].name.toUpperCase())+" ")])])])]):_vm._e()}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }