<template>
  <div class="mb-1 p-0 mx-0 waybill-index-top-nav-select">
    <!--begin::Nav Tabs-->
    <ul class="dashboard-tabs nav nav-pills nav-primary d-flex flex-nowrap m-0 p-0" role="tablist">
      <li v-for="(row, index) in data" :key="index" v-if="Number(row.parent_id) === 0" @click="modelProxy = row.id"
        class="rounded d-flex align-items-center justify-content-center items-center my-li nav-item mb-lg-0">
        <a class="d-flex align-items-center justify-content-center items-center" :class="{ active: isActive(row.id) }"
          data-toggle="pill">
          <span class="nav-icon w-auto d-flex align-items-center row justify-content-center items-center text-center">
            <span v-if="row.id === 7" class="col-12 text-center waybill-nav-icon" v-html="getIconByKey('icons.waybill.transfer', {
              class: 'w-20px h-20px d-inline-block object-fill cursor-pointer',
              style: isActive(row.id) ? '' : 'opacity: 0.5;'
            })">
            </span>
            <span v-if="row.id === 2" class="col-12 text-center waybill-nav-icon" v-html="getIconByKey('icons.waybill.accessory', {
              class: 'w-20px h-20px d-inline-block object-fill cursor-pointer',
              style: isActive(row.id) ? '' : 'opacity: 0.5;'
            })">
            </span>
            <span v-if="row.id === 1" class="col-12 text-center waybill-nav-icon" v-html="getIconByKey('icons.waybill.fabric', {
              class: 'w-20px h-20px d-inline-block object-fill cursor-pointer',
              style: isActive(row.id) ? '' : 'opacity: 0.5;'
            })">
            </span>
            <span class="nav-text col-12 py-2 px-2 font-weight-boldest text-center d-inline-block pl-2 second-waybill-naw-text"
              :id="isActive(row.id) ? 'active-navy' : ''">{{
                row.translations[0].name.toUpperCase()
              }}
            </span>
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TopWaybillNav",
  props: {
    model: {
      default: 1,
    },
    data: {
      required: true,
    }
  },
  computed: {
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value);
      }
    }
  },
  methods: {
    isActive(value) {
      return Number(this.model) === Number(value);
    },
  }
}
</script>

<style scoped>
.svg-icon.svg-icon-2x svg {
  width: 2.0rem !important;
  height: 2.0rem !important;
}

.nav-link {
  padding: 0.5rem 0.5rem !important;
}

.dashboard-tabs li {
  cursor: pointer;
}

.text-xl {
  font-size: 1.25rem !important;
}
</style>


<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.demo.scss';

$primary:'#78cdea';
.svg-icon.svg-icon-2x svg {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.nav-link {
  padding: 0.5rem 0.5rem !important;
}

.dashboard-tabs li {
  cursor: pointer;
}

.text-xl {
  font-size: 20px !important;
}

.nav.nav-pills.nav-primary .show>.nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-primary .nav-link.active .nav-icon .svg-icon svg g [fill] {
  fill: #78cdea !important;
}

.nav.nav-pills.nav-primary .nav-link.active {
  color: #78cdea !important;
  background-color: transparent !important;
}

.nav.nav-pills.nav-primary .nav-link.active .nav-text {
  color: $primary !important;
}

.dashboard-tabs {
  .nav-item {
    margin-right: 0;
    border: none !important;

    .border {
      border-radius: 0 !important;
      border: none !important;
    }
  }
}

#active-navy {
  color: $navy-blue !important;
}

.dashboard-tabs {
  list-style: none;
}

</style>

<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/waybill/topWaybillNav.scss";
/*Mobile End*/
</style>